import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import css from '../styles.module.scss';
import { ReactComponent as EmptySvg } from '../icons/empty.svg';

interface IEmptyProps {
  handleCreateCampaign: () => void;
  text: string;
}

const Empty: FC<IEmptyProps> = ({ handleCreateCampaign, text }) => {
  const { t } = useTranslation();
  return (
    <div className={css.empty}>
      <EmptySvg />
      <div className={css.empty_text}>{text}</div>
      <Button
        title={t(`campaigns_page.create_btn`)}
        height={36}
        buttonAttributes={{
          onClick: handleCreateCampaign,
        }}
      />
    </div>
  );
};

export default Empty;
