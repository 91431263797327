import { TCampaignOrd } from 'domains/contract/model/types';
import React, { FC } from 'react';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import css from '../styles.module.scss';
import Status from '../Status';

interface ICreatedProps {
  data: TCampaignOrd;
}

const Created: FC<ICreatedProps> = ({ data }) => {
  const baseurl = process.env.REACT_APP_DEV_API !== 'true' ? '' : '-dev';

  return (
    <div className={css.crated}>
      <Status status={data.status} />
      <div className={css.crated_info}>
        <div className={css.name}>
          <TooltipPoratal hint={data.name} hintWidth={300}>
            <a
              href={`https://grid${baseurl}.terratraf.com/ORD/Campaign/${data.uuid}`}
              target="_blank"
              rel="noreferrer"
            >
              {data.name}
            </a>
          </TooltipPoratal>
        </div>
        <div className={css.uid}>{data.uuid}</div>
      </div>
    </div>
  );
};

export default Created;
